import React from "react"
import "./products.scss"
import { MdKeyboardArrowRight } from "react-icons/md"
import { Link } from 'gatsby'

const products = [
  {
    heading: "Performance for all your daily tasks",
    title: "The Antsle one Series",
    image: "https://antsle.com/wp-content/uploads/2020/02/antsle_front.png",
    description:
      "The Antsle one Series comes in two lines: The Antsle one D models, our memory monster, and the Antsle one XD, with a powerful Xeon-D processor.",
    link: "/products/antsle/server#0",
    buyLink: "/buy",
  },
  {
    heading: "The Power of the Private Cloud in your pocket",
    title: "The Antsle Nano",
    image:
      "https://antsle.com/wp-content/uploads/2020/02/AntsleNanoFinal-1-1.png",
    description:
      "Clouds don't have to be huge. The Antsle Nano combines all the power of antMan and the Private Cloud in a device that fits on your pocket. And: Although it's fanless, it certainly isn't fan-less.",
    link: "https://go.antsle.com/antsle-nano/",
    buyLink: "https://shop.antsle.com/antsle-nano-128gb/",
  },
  {
    heading: "Ready for the challenges of tomorrow",
    title: "The Antsle two Series",
    image:
      "https://antsle.com/wp-content/uploads/2019/11/antsle-Two-server-rackmount-2.png",
    description:
      "With the Antsle two, we took it to the next level. It's all about performance: wether you need to run a crazy amount  of antlets or just power a very demanding setup - the Antsle two can handle them all.",
    link: "/products/antsle-server/#antsle-two",
    buyLink: "https://antsle.com/shop-antsle-two-rackmount-servers/#0",
  },
]

export default function Products() {
  return (
    <>
      {products.map((product) => (
        <div
          key={product.title}
          className="imgr bg-gray-200 dark:bg-gray-800 rounded-3xl mx-12"
        >
          <div className="imgr__container">
            <div className="imgr__container--left">
              <h2 className="text-gray-900 dark:text-gray-200">
                {product.heading}
              </h2>
              <h1 className="text-black dark:text-gray-200">{product.title}</h1>
              <p className="text-gray-700 dark:text-gray-400">
                {product.description}
              </p>
              <Link to={product.link} className="p-4">
                <button className="inline-flex items-center mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  Learn More
                </button>
              </Link>
              <Link to={product.buyLink} className="p-4">
                <button className="inline-flex items-center mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                  Buy Now <MdKeyboardArrowRight />
                </button>
              </Link>
            </div>
            <div className="imgr__container--right">
              <img
                className="imgr__container--img"
                src={product.image}
                placeholder="blurred"
                alt={product.title}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
