import React from 'react'

export default function Clients() {
    return (
     <div className="mt-4 ClientsIcon">
            <div className="text-center text-base">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    You're in Good Company
                </h2>
                <h3 className="mt-3 text-lg text-gray-500 sm:text-2xl">
                Trusted by forward-thinking companies of all shapes and sizes
                </h3>
            </div>
        <div className="icons">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/dell.png" alt="Dell" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/usarmy-logo.png" alt="US Army" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/nordstrom.png" alt="Nordstrom" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2019/04/att.png" alt="AT&T" />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/symantec.png" alt="Symantech" />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/generalelectric.png" alt="Symantech"
                />
                </div>
            </div>
            </div>
        </div>
        <div className="icons">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/dell.png" alt="Dell" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/usarmy-logo.png" alt="US Army" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/nordstrom.png" alt="Nordstrom" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2019/04/att.png" alt="AT&T" />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/symantec.png" alt="Symantech" />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/generalelectric.png" alt="Symantech"
                />
                </div>
            </div>
            </div>
        </div>
        <div className="icons">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/dell.png" alt="Dell" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/usarmy-logo.png" alt="US Army" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/nordstrom.png" alt="Nordstrom" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2019/04/att.png" alt="AT&T" />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/symantec.png" alt="Symantech" />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-25" src="https://antsle.com/wp-content/uploads/2018/10/generalelectric.png" alt="Symantech"
                />
                </div>
            </div>
            </div>
        </div>
    </div>
    )
  }
  