import React from 'react'
import PropTypes from 'prop-types'

export default function Heading ({title, description, smalltitle}) {
    return (
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-red-800 tracking-wide uppercase">{smalltitle}</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 dark:text-gray-200 sm:text-5xl sm:tracking-tight lg:text-6xl">
              {title}
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-600 dark:text-gray-400">
              {description}
            </p>
          </div>
        </div>
    )
  }
  
  Heading.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }