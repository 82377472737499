import React from 'react'

export default function CTAFreeD() {
    return (
      <div className="bg-red-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
            <span className="block">Download antMan Free</span>
            <span className="block text-red-800">Get the Community Edition for personal use.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://antsle.com/products/antmanedgelinux/download/"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-700 hover:bg-red-500"
              >
                Download Now
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  