import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Clients from "../components/Clients"
import CTAdemo from "../components/CTAdemo"
import LadderImgR from "../components/Blocs/LadderImgR"
import LadderImgL from "../components/Blocs/LadderImgL"
import Heading from "../components/Blocs/Heading"
import Features from "../components/Features"
import CTAFreeD from "../components/CTAFreeD"
import Products from "../components/Products"
import { Link } from "gatsby"

const ComponentsPage = () => (
  <Layout>
    <Seo title="Home" />
    <Heading
      title="Astle Appliances"
      description="antMan preinstalled. Ready for prod in minutes."
      smalltitle="products"
    />
    <LadderImgR
      image="https://antsle.com/wp-content/uploads/2020/05/antman-dashboard-on-laptop-small.png"
      alt="image alt desc"
    >
      <h1 className="text-black dark:text-gray-200">
        A Cloud that's 100% Yours
      </h1>
      <p className="text-gray-800 dark:text-gray-400">
        Manage your own VPSs in a private, on-prem cloud
      </p>
      <p className="text-gray-700 dark:text-gray-500">
        Deploy antlets (your virtual private servers) in a world class
        management console right from your browser. We've got everything you
        need for your on-prem, private cloud — from backups to load balancing.
        antMan runs on edgeLinux, Antsle's purpose built OS.
      </p>
      <Link to="/products/antmanedgelinux">
        <button className="inline-flex items-center mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          Learn More
        </button>
      </Link>
    </LadderImgR>

    <LadderImgL
      image="https://antsle.com/wp-content/uploads/2020/05/antman-dashboard-on-laptop-small.png"
      alt="image alt desc"
    >
      <h1 className="text-black dark:text-gray-200">
        A Cloud that's 100% Yours
      </h1>
      <p className="text-gray-800 dark:text-gray-400">
        Manage your own VPSs in a private, on-prem cloud
      </p>
      <p className="text-gray-700 dark:text-gray-500">
        Deploy antlets (your virtual private servers) in a world class
        management console right from your browser. We've got everything you
        need for your on-prem, private cloud — from backups to load balancing.
        antMan runs on edgeLinux, Antsle's purpose built OS.
      </p>
      <Link to="/products/antmanedgelinux">
        <button className="inline-flex items-center mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          Learn More
        </button>
      </Link>
    </LadderImgL>
    <Features />
    <CTAFreeD />
    <CTAdemo />
    <Clients />
  </Layout>
)

export default ComponentsPage
